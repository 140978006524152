<template>
  <div class="">
    <v-img lazy-src="@/assets/404.png" max-height="80vh" src="@/assets/404.png">
    </v-img>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
</style>
